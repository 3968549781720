.brandSearch {
  @apply w-full relative text-sm bg-white border rounded-brand border-base-border transition ease-in-out mb-5;

  svg {
    @apply h-8 w-8;
  }
}

.brandSearchInput {
  @apply w-full bg-transparent pl-4 pr-20;
  height: 48px;
  color: #000;

}

.brandSearchInput:focus {
  @apply outline-none transition-colors border rounded-brand border-brand;
  box-shadow: 0 0 0 1px var(--brand);
}

.iconContainer {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
}

.icon {
  @apply h-5 w-5;
}

.brandSearchButton {
  @apply absolute inset-y-0 right-0 px-2 border-l border-base-border;
}

.brandSearchIconWrapper {
  @apply absolute inset-y-0 right-0 flex justify-center items-center px-2 border-l border-base-border;
}

.filtersSidebar {
  @apply sm:flex flex-col w-full relative text-sm px-3 py-3 rounded-brand;
}

.borderAround {
  @apply border rounded-lg border-solid border-base-border;
}

.shadowAround {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

.borderRight {
  @apply border-r pr-4;
}

.borderNone {
  @apply px-5 py-7;
}

.filtersDelimiter {
  @apply w-full border-b border-solid border-b-base-border my-5;
}

.filterByPrice {
  @apply flex flex-row md:justify-start items-center font-medium text-black mb-4 gap-x-2.5;
}

.productsHeader {
  @apply flex text-32 font-bold text-black -mt-1 mb-5;
}

.sortDropdownWrapper {
  @apply inline-flex flex-row flex-wrap items-center text-sm z-30;
}

.pagination {
  @apply flex justify-center w-full sm:mt-10 sm:mb-20 mt-2.5 mb-7.5;
}

.pagination li {
  @apply sm:mx-2 mx-0.5 sm:w-17 sm:h-16;
}

.pagination li:first-child {
  @apply w-auto;
}

.pagination li:last-child {
  @apply w-auto;
}

.pagination a {
  @apply w-full sm:px-0 px-1 h-full sm:text-lg text-xs inline-flex flex-col items-center justify-center rounded-brand font-semibold hover:bg-brand-hover hover:text-white transition-colors;
  line-height: 48px;
}

.pagination .selected>a {
  @apply sm:text-lg sm:px-0 sm:mx-0 px-1 text-xs inline-flex flex-col items-center justify-center font-semibold pointer-events-none bg-brand text-white;
  line-height: 48px;
}

.isHomeBrandPagination .selected>a {
  @apply bg-[#2A1A11];
}

.isHomeBrandPagination a {
  @apply text-[#2A1A11];
}
.pagination .empty>a {
  @apply sm:text-lg text-xs inline-flex flex-col items-center justify-center font-semibold pointer-events-none;
  line-height: 48px;
}

.pagination .disabled>a {
  @apply cursor-not-allowed opacity-50 hover:bg-white hover:text-inherit;
}

.radio {
  @apply my-2;

  & label {
    @apply text-base
  }
}

.checkbox label {
  @apply whitespace-nowrap text-ellipsis overflow-hidden text-base
}

.filtersButton {
  @apply flex border-sortFilterColor border-2 px-8 py-3 rounded-brand items-center font-bold text-sortFilterColor text-lg sm:hidden;
  letter-spacing: 1px;

  & svg {
    @apply mr-2;
  }
}