.root {
  @apply flex items-center;
}

.label {
  @apply text-sm text-inherit cursor-pointer;
}

.input[type='radio'] {
  @apply appearance-none m-0 mr-[11px] h-5 w-5 border border-base-border rounded-full
  flex justify-center items-center cursor-pointer;
}

.input[type='radio']::before {
  @apply scale-0 duration-150 ease-in-out;
  content: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.44912 8.6786L1.13019 5.25802L0 6.41462L4.44912 11L14 1.1566L12.8778 0L4.44912 8.6786Z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.input[type='radio']:checked {
  @apply bg-brand;
}

.input[type='radio']:checked::before {
  @apply scale-100;
}

.input[type='radio']:hover,
.input[type='radio']:focus,
.input[type='radio']:checked {
  @apply outline-none border-brand;
}

.input[type='radio']:disabled {
  @apply bg-grayBg;
  @apply cursor-default;
}

.input[type='radio']:disabled + label {
  @apply cursor-default;
}

.input[type='radio']:checked:disabled {
  @apply border-brand bg-brand opacity-60;
}

.invalid {
  @apply outline-none;
  border: 1px solid var(--red) !important;
  box-shadow: 0 0 0 1px red;
}

.errorMessage {
  @apply text-sm text-red mt-1;
}
