.root {
  @apply text-sm text-black bg-white pt-5 pb-1.5 px-3.5 border rounded-brand border-base-border appearance-none transition duration-150 ease-in-out;
  width: inherit;
}

.root:focus {
  @apply outline-none border-brand;
  box-shadow: 0 0 0 1px var(--brand) inset;
}

.invalid {
  @apply outline-none border-red;
  box-shadow: 0 0 0 1px red;
}

.invalid:focus {
  @apply outline-none border-red;
  box-shadow: 0 0 0 1px red;
}

.placeholder {
  @apply py-3;
}

.errorMessage {
  @apply text-sm text-red mt-1 ml-2;
}

.errorMessage:empty {
  display: none;
}

.priceFilterLabel {
  white-space: nowrap;
}