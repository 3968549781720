
.sortDropdownMenu {
  @apply flex flex-col rounded-brand w-full mt-2 py-2 overflow-hidden absolute z-30 top-12;
  border: 1px solid var(--baseBorder);
  background-color: #ffffff;
  color: #888;
}
.sortDropdownBtn {
 
  background-color: #ffffff;
  @media (min-width: 640px) {
    border: 1px solid var(--baseBorder);
    color: #000;
    height: 48px;
  }
}
.sortDropdownLink {
  @apply ease-in-out px-4 py-2;
}


.filtersButton {
  @apply flex border-sortFilterColor border-2 px-8 py-3 rounded-brand items-center font-bold text-sortFilterColor text-lg;
  letter-spacing: 1px;

  & svg {
    @apply mr-2;
  }
}