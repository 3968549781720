.root {
  @apply flex justify-center align-middle items-center h-9 sm:mb-8 mb-5;
}

.buttons {
  @apply flex w-full text-lg font-medium items-center overflow-x-scroll text-black relative;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.buttons::-webkit-scrollbar {
  @apply h-0 w-0;
}

.item {
  @apply flex justify-center items-center rounded-brand text-sm leading-6 h-full mr-4 bg-grayBg px-2.5;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  padding-block: 7px;

  &:hover {
    @apply text-white bg-brand-hover;
  }
}

.activeItem {
  @apply bg-brand;
  color: #ffffff;
}

.arrowWrap {
  @apply flex justify-center items-center;
  height: 35px;
  width: 40px;
  margin: 0 10px;
}

.arrowButton {
  @apply flex justify-center items-center cursor-pointer;
}
