.skeletonItem {
  @apply block w-full sm:w-1/4 px-2.5 py-6;
}

.skeleton {
  @apply block w-full;
  padding: 0 0 160%;
  background-image: linear-gradient(270deg, #fff, #eaeaea, #fff, #fafafa);
  background-size: 400% 100%;
  animation: loading 8s ease-in-out infinite;
}

.wrapper {
  @apply block relative;

  &:not(.show)::before {
    content: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    background-image: linear-gradient(270deg, #fff, #eaeaea, #fff, #fafafa);
    background-size: 400% 100%;
    animation: loading 8s ease-in-out infinite;
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
